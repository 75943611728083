import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "../../constants";
import {
    Flex,
    Checkbox
  } from "@chakra-ui/react";
  
  import HistoryItem from "views/admin/prodPlusVendus/components/HistoryItem";
  import Card from "components/card/Card.js";
  import defaultImage from "assets/img/meds.png";
  import {getAuthToken} from "../../helpers/helpers"
  
  export default function Favoris() {
    const [items, setItems] = useState([]);
  
    useEffect(() => {
      const fetchData = async () => {
        const token = getAuthToken();

        try {
          const response = await axios.get(
            `${API_URL}/api/list_hidden_medicines`,
            {
              headers: {
                "x-access-token": token,
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setItems(response.data);
        } catch (error) {
          console.log("Error making the request:", error);
        }
      };
  
      fetchData();
    }, []);
  
    const handleMaskedChange = async (item) => {
      const token = getAuthToken();

      try {
        const response = await axios.post(
          `${API_URL}/api/update_medicine`,
          {
            medicineId: item._id,
            hide: false,
          },
          {
            headers: {
              "x-access-token": token,
              Authorization: `Bearer ${token}`,
            },
          }
        );
  
        //console.log("API Response:", response.data);
  
        if (response.status === 200) {
          setItems((prevItems) =>
            prevItems
              .map((currentItem) =>
                currentItem._id === item._id
                  ? {
                      ...currentItem,
                      hidden: false, // set the attribute as it is set in the backend
                    }
                  : currentItem
              )
              .filter((currentItem) => currentItem.hidden)
          );
        } else {
          console.log("Failed to update the medicine's masked status.");
        }
      } catch (error) {
        console.error("Error updating masked status:", error);
      }
    };
  
    return (
      <Flex flexDirection="column" py="70px">
        <Card p="0px">
          <div>
            {items.length > 0 ? (
              items.map((item, index) => (
                <Flex key={index} alignItems="center" mb="10px">
                  <Checkbox
                    defaultChecked
                    isChecked={item.hidden}
                    onChange={() => handleMaskedChange(item)}
                    // mr="-10"
                    p="10px"
                  />
                  <HistoryItem
                    name={item.specialite}
                    image={item.photo || defaultImage}
                    price={item.prix}
                    forme={item.forme}
                    text="TND"
                  />
                </Flex>
              ))
            ) : (
            <p>No items to display</p>
            )}
          </div>
        </Card>
      </Flex>
    );
  }
