import React from "react";
import {
  Flex,
  Link,
  List,
  ListItem,
  useColorModeValue,
} from "@chakra-ui/react";

export default function Footer() {
  const textColor = useColorModeValue("gray.400", "white");

  return (
    <Flex
      zIndex="3"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      px={{ base: "30px", md: "50px" }}
      pb="30px"
    >
      <List display="flex" justifyContent="center" width="100%">
        <ListItem
          me={{
            base: "20px",
            md: "44px",
          }}
        >
          <Link
            fontWeight="500"
            color={textColor}
            href="mailto:contact@saydalid.com"
          >
            Support
          </Link>
        </ListItem>
        <ListItem>
        {/* <Link fontWeight="500" color={textColor} href="/terms" target="_blank" rel="noopener noreferrer"> */}
          <Link fontWeight="500" color={textColor} href="/terms" target="_blank" rel="noopener noreferrer">
            Conditions d'utilisations
          </Link>
        </ListItem>
      </List>
    </Flex>
  );
}
