import { Box, Icon, SimpleGrid, useColorModeValue, Input, InputGroup, InputLeftElement ,InputRightElement} from "@chakra-ui/react";
import Profils from "views/admin/default/components/Profils";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { MdBarChart } from "react-icons/md";
import { GrMoney } from "react-icons/gr";
import { MdClose , MdOutlineSell, MdSearch } from "react-icons/md";
import ProperimeSoldChart from "views/admin/default/components/Properime";
import Patients from "views/admin/default/components/Patients";
import Utilisations from "views/admin/default/components/Utilisations";
import PlaintesConsultees from "views/admin/default/components/PlaintesConsultees";
import { CiCalendar } from "react-icons/ci";
import InteractionsStat from "views/admin/default/components/InteractionsStat";
import { API_URL } from "../../../constants";
import {getAuthToken} from "../../../helpers/helpers"

export default function UserReports() {
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const [totalProp, setTotalProp] = useState(0);
  const [products, setProducts] = useState(0);
  const [equivalent, setEquivalent] = useState(0);
  const [totalVentes, setTotalVentes] = useState(0);
  const [venteProperime, setVenteProperime] = useState(0);
  const [totalPropVend, setTotalPropVend] = useState(0);
  const [selectedDate, setSelectedDate] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const token = getAuthToken();

      const response2 = axios
        .get(`${API_URL}/api/properemption_medicament`, {
          headers: { 
            "x-access-token": token,
            Authorization: `Bearer ${token}`,
           },
        })
        .catch((error) => {
          console.log("Error fetching properemption_medicament:", error);
          return { data: [] };
        });

      const response3 = axios
        .get(`${API_URL}/api/cart`, {
          headers: { 
            "x-access-token": token,
            Authorization: `Bearer ${token}`,
           },
        })
        .catch((error) => {
          console.log("Error fetching cart:", error);
          return { status: 404, data: [] };
        });

      try {
        const [res2, res3] = await Promise.all([response2, response3]);

        let filtered = res2.data.filter(
          (elem) => !elem.masked && elem.qte_stock > 0
        );

        let price = 0;
        let total = 0;
        filtered.forEach((item) => {
          price += item.prix * item.qte_stock;
          total += item.qte_stock;
        });
        setProducts(total);
        setEquivalent(price.toFixed(3));

        let counter = [];
        filtered.forEach((item) => {
          if (!counter.includes(item.specialite)) {
            counter.push(item.specialite);
          }
        });
        setTotalProp(counter.length);

        function sixMonths(date1, date2) {
          const d1 = date1 instanceof Date ? date1 : new Date(date1);
          const d2 = date2 instanceof Date ? date2 : new Date(date2);

          const monthDiff =
            (d2.getFullYear() - d1.getFullYear()) * 12 +
            (d2.getMonth() - d1.getMonth());

          return Math.abs(monthDiff) <= 6;
        }

        if (res3.status !== 404) {
          let ventes = 0;
          let venteProp = 0;
          let propVend = 0;

          const today = new Date();

          // Comparer la date sélectionnée avec la date "created_at"
          const filteredCartItems = res3.data.filter((item) => {
            const createdAt = new Date(item.created_at);
            if (selectedDate) {
              // Comparer seulement la partie date (sans l'heure)
              return createdAt.toISOString().split("T")[0] === selectedDate;
            }
            return true;
          });

          filteredCartItems.forEach((item) => {
            ventes += item.items.length;

            item.items.forEach((elem) => {
              if (elem.medicamentDetails) {
                const peremptionDate = new Date(
                  elem.medicamentDetails.date_peremption
                );
                if (sixMonths(today, peremptionDate)) {
                  venteProp++;
                  propVend += elem.quantity * elem.medicamentDetails.prix;
                }
              }
            });
          });

          setTotalVentes(ventes);
          setVenteProperime(venteProp);
          setTotalPropVend(propVend);
        }
      } catch (error) {
        console.log("Error during fetching data:", error);
      }
    };

    fetchData();
  }, [selectedDate]); 

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
       <Box display="flex" justifyContent="flex-end" mb="20px" w="100%">
        <InputGroup size="lg" w={{ base: "63%", md: "30%" }}> 
          <InputLeftElement
            pointerEvents="none"
            children={<Icon as={MdSearch} color="gray.400" w="24px" h="24px" />}
          />
          <Input
            type="date"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
            bg="white"
            borderRadius="20px"
            boxShadow="sm"
            max={new Date().toISOString().split("T")[0]} 
            border="2px solid" 
            _hover={{
              borderColor: "blue.500",
            }}
            _focus={{
              borderColor: "blue.500",
              boxShadow: "0 0 0 1px blue.500",
            }}
          />
          {selectedDate && (
            <InputRightElement>
              <Icon
                as={MdClose}
                color="gray.400"
                cursor="pointer"
                w="24px"
                h="24px"
                onClick={() => setSelectedDate("")}
              />
            </InputRightElement>
          )}
        </InputGroup>
      </Box>

    <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap="20px"
        mb="20px"
      >
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              //bg="linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)"
              icon={<Icon w="28px" h="28px" as={MdOutlineSell} color="blue" />}
            />
          }
          name="Nombre total de produits vendus"
          value={totalVentes}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={CiCalendar} color={brandColor} />
              }
            />
          }
          name="Produits propérimés vendus"
          value={venteProperime}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={<Icon w="32px" h="32px" as={GrMoney} color={brandColor} />}
            />
          }
          name="Equivalent pro-périmés vendus en TND"
          value={Math.round((totalPropVend + Number.EPSILON) * 100) / 100}
        />
        <MiniStatistics
          name="Produits pro-périmés en stock"
          value={products}
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdBarChart} color={brandColor} />
              }
            />
          }
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={CiCalendar} color={brandColor} />
              }
            />
          }
          name="Nombre de spécialités propérimées"
          value={totalProp}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={<Icon w="32px" h="32px" as={GrMoney} color={brandColor} />}
            />
          }
          name="Equivalent pro-périmés en stock en TND"
          value={equivalent}
        />
      </SimpleGrid>

      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px">
      <Utilisations selectedDate={selectedDate} />
      <PlaintesConsultees selectedDate={selectedDate} />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap="20px" mb="20px">
  {/* <CheckTable columnsData={columnsDataCheck} tableData={tableDataCheck} /> */}

  {/* This SimpleGrid will have a single column on mobile and will stack the children */}
  <SimpleGrid
  columns={{ base: 1, md: 2, xl: 2 }}
  gap={{ base: "20px", md: "40px", xl: "180px" }}
>
  <Box w={{ base: "60%", md: "100%" }}> {/* Set width for mobile view */}
    <Patients selectedDate={selectedDate} />
  </Box>
  <Box w={{ base: "28%", md: "100%" }}> {/* Set width for mobile view */}
    <Profils selectedDate={selectedDate} />
  </Box>
</SimpleGrid>

      </SimpleGrid>
      <SimpleGrid  columns={{ base: 1, md: 2, xl: 2 }}  gap={{ base: "20px",xl: "30px" }}>
      <ProperimeSoldChart/>
      <InteractionsStat/>
      </SimpleGrid>

    </Box>
  );
}