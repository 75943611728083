import React, { useState, useEffect } from "react";
import axios from "axios";
import { Flex } from "@chakra-ui/react";
import { API_URL } from "../../../constants";
import {getAuthToken} from "../../../helpers/helpers"

import HistoryItem from "views/admin/prodPlusVendus/components/HistoryItem";
import defaultImage from "assets/img/meds.png";
export default function Vendus() {
  const [products, setProducts] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const token = getAuthToken();

      try {
        const response = await axios.get(`${API_URL}/api/cart`, {
          headers: {
            "x-access-token": token,
            Authorization: `Bearer ${token}`,
          },
        });
        //console.log("cart", response.data);
        const nameCounts = response.data.reduce((acc, med) => {
          med.items.forEach((item) => {
            //console.log("test1", item.medicamentDetails);
            const itemName = item?.name;
            const itemImage = item.medicamentDetails?.photo;
            const itemPrice = item.medicamentDetails?.prix;
            const itemForme = item.medicamentDetails?.forme;

            if (Object.keys(acc).includes(itemName)) {
              acc[itemName].count += 1;
            } else {
              acc[itemName] = {
                count: 1,
                image: itemImage,
                price: itemPrice,
                forme: itemForme,
              };
            }
          });
          return acc;
        }, {});

        //console.log("nameCounts", nameCounts);
        const sortedNameCounts = Object.entries(nameCounts)
          .sort((a, b) => b[1].count - a[1].count)
          .slice(0, 10);
        //console.log("sorted", sortedNameCounts);
        const top10Products = sortedNameCounts.map(([name, details]) => ({
          name: name,
          image: details.image,
          price: details.count, // represents how many times a product is purchased
          forme: details.forme,
        }));
        setProducts(top10Products);
        //console.log("top10", top10Products);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setProducts([]);
        } else {
          console.log("Error making the request:", error);
          setProducts([]);
        }
      }
    };

    fetchData();
  }, []);
  return (
    <Flex
      flexDirection="column"
      //gridArea={{ xl: "1 / 3 / 2 / 4", "2xl": "1 / 2 / 2 / 3" }}
      py="70px"
    >
      <div>
        {products.length > 0 ? (
          products.map((item, index) => (
            <HistoryItem
              key={index}
              name={item.name}
              image={item.image || defaultImage}
              price={item.price}
              forme={item.forme}
            />
          ))
        ) : (
          <p>No items to display</p>
        )}
      </div>
    </Flex>
  );
}
