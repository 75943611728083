import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import PieChart from "components/charts/PieChart";
import { VSeparator } from "components/separator/Separator";
import { API_URL } from "../../../../constants";
import {getAuthToken} from "../../../../helpers/helpers"

export const pieChartOptions = {
  labels: ["Confirm Cart", "Show Medic", "Zoom Medic Image", "Afficher Traitement"],
  colors: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0"],
  chart: {
    width: "50px",
  },
  legend: {
    show: false,
  },
  dataLabels: {
    enabled: false,
  },
  fill: {
    colors: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0"],
  },
  tooltip: {
    enabled: true,
    theme: "dark",
  },
};

export default function InteractionsPieChart() {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const cardColor = useColorModeValue("white", "navy.700");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );

  const [chartData, setChartData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [totalInteractions, setTotalInteractions] = useState(0); 

  useEffect(() => {
    const fetchInteractions = async () => {
      setIsLoading(true);
      const token = getAuthToken();

      try {
        const [resConfirmCart, resShowMedic, resZoomMedicImage, resAfficherTraitement] = await Promise.all([
          axios.get( `${API_URL}/api/track/action/CONFIRM_CART`,
            {
              headers: {
                "x-access-token": token,
                Authorization : `Bearer ${localStorage.getItem("token")}`

              },
            }),
          axios.get( `${API_URL}/api/track/action/SHOW_MEDIC`,
            {
              headers: {
                "x-access-token": token,
                 Authorization : `Bearer ${localStorage.getItem("token")}`

              },
            }),
          axios.get(`${API_URL}/api/track/action/ZOOM_MEDIC_IMAGE`, {
            headers: {
              "x-access-token": token,
               Authorization : `Bearer ${localStorage.getItem("token")}`
            },
           }),
          axios.get(`${API_URL}/api/track/action/AFFICHER_TRAITEMENT`,  {
            headers: {
              "x-access-token": token,
               Authorization : `Bearer ${localStorage.getItem("token")}`

            },
          }),
        ]);

        const total = resConfirmCart.data.length +
          resShowMedic.data.length +
          resZoomMedicImage.data.length +
          resAfficherTraitement.data.length;
          if (total === 0) {
           setTotalInteractions(0);
           setChartData([0, 0, 0, 0]);
           return;
              }
        setTotalInteractions(total); 

        const confirmCartPercentage = (resConfirmCart.data.length / total) * 100;
        const showMedicPercentage = (resShowMedic.data.length / total) * 100;
        const zoomMedicImagePercentage = (resZoomMedicImage.data.length / total) * 100;
        const afficherTraitementPercentage = (resAfficherTraitement.data.length / total) * 100;

        setChartData([
          confirmCartPercentage,
          showMedicPercentage,
          zoomMedicImagePercentage,
          afficherTraitementPercentage,
        ]);
      } catch (error) {
        console.log("Error fetching interactions:", error);
        setChartData([0, 0, 0, 0]);
      }
      setIsLoading(false);
    };

    fetchInteractions();
  }, []);

  if (isLoading) {
    return <div>Chargement...</div>;
  }

  if (chartData === null) {
    return <div>No data available</div>;
  }

  return (
    <Card   direction="column" w="100%">
      <Flex justifyContent="space-between" alignItems="center" w="100%" mb="8px">
        <Text color={textColor} fontSize="md" fontWeight="600" mt="4px">
          Interactions : {totalInteractions} 
        </Text>
      </Flex>

      <PieChart
        w="100%"
        p="15px"
        chartData={chartData}
        chartOptions={{
          ...pieChartOptions,
          tooltip: {
            ...pieChartOptions.tooltip,
            y: {
              formatter: (value) => `${value.toFixed(1)}%`,
            },
          },
        }}
      />

      <Card
        bg={cardColor}
        justifyContent="center"
        flexDirection="row"
        boxShadow={cardShadow}
        w="100%"
        p="15px"
        px="20px"
        mt="15px"
        mx="auto"
      >
        {["Confirm Panier", "Show Medic", "Zoom Medic Image", "Afficher Traitement"].map((label, index) => (
          <React.Fragment key={index}>
            <Flex direction="column" py="5px" align="center">
              <Flex align="center">
                <Box h="8px" w="8px" bg={pieChartOptions.colors[index]} borderRadius="50%" me="4px" />
                <Text fontSize="xs" color="secondaryGray.600" fontWeight="700" mb="5px">
                  {label}
                </Text>
              </Flex>
              <Text fontSize="lg" color={textColor} fontWeight="700">
                {chartData[index].toFixed(1)}%
              </Text>
            </Flex>
            {index < 3 && <VSeparator mx="30px" />}
          </React.Fragment>
        ))}
      </Card>
    </Card>
  );
}
