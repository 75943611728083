import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import PieChart from "components/charts/PieChart";
import { VSeparator } from "components/separator/Separator";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_URL } from "../../../../constants";
import {getAuthToken} from "../../../../helpers/helpers"

export const pieChartOptions = {
  labels: ["Homme", "Femme", "Enfant"],
  colors: ["#0066FF", "#33FFFF", "#678BF4"],
  chart: {
    width: "50px",
  },
  states: {
    hover: {
      filter: {
        type: "none",
      },
    },
  },
  legend: {
    show: false,
  },
  dataLabels: {
    enabled: false,
  },
  hover: { mode: null },
  plotOptions: {
    donut: {
      expandOnClick: false,
      donut: {
        labels: {
          show: false,
        },
      },
    },
  },
  fill: {
    colors: ["#0066FF", "#33FFFF", "#678BF4"],
  },
  tooltip: {
    enabled: true,
    theme: "dark",
  },
};

export default function Conversion({ selectedDate, ...rest }) {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const cardColor = useColorModeValue("white", "navy.700");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );

  const [chartData, setChartData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Démarre le chargement
      let Homme = 0;
      let Femme = 0;
      let Enfant = 0;

      const token = getAuthToken();

      try {
        const response = await axios.get(
          `${API_URL}/api/track/action/AFFICHER_TRAITEMENT`,
          {
            headers: {
              "x-access-token": token,
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Filtrer les résultats en fonction de selectedDate
        const filteredData = response.data.filter((item) => {
          const createdAt = new Date(item.created_at);
          if (selectedDate) {
            return createdAt.toISOString().split("T")[0] === selectedDate;
          }
          return true;
        });

        const total = filteredData.length;
        if (total === 0) {
          setChartData([0, 0, 0]); // Aucun résultat, retourner 0 pour chaque catégorie
          setLoading(false); // Arrêter le chargement
          return;
        }

        // Parcourir les données pour déterminer la répartition
        filteredData.forEach((item) => {
          const gender = item.data?.patientProfile?.gender;
          if (gender === "F") Femme++;
          else if (gender === "H") Homme++;
          else if (gender === "E") Enfant++;
        });

        // Calculer les pourcentages
        const FemmePercentage = (Femme / total) * 100 || 0;
        const HommePercentage = (Homme / total) * 100 || 0;
        const EnfantPercentage = (Enfant / total) * 100 || 0;

        setChartData([HommePercentage, FemmePercentage, EnfantPercentage]);
        setLoading(false); // Arrêter le chargement
      } catch (error) {
        console.error("Error fetching data:", error);
        setChartData([0, 0, 0]); // En cas d'erreur, afficher 0%
        setLoading(false); // Arrêter le chargement
      }
    };

    fetchData();
  }, [selectedDate]); // selectedDate comme dépendance

  // Afficher l'état de chargement
  if (loading || chartData === null) {
    return <div>Chargement...</div>;
  }

  return (
    <Card p="20px" align="center" direction="column" w="160%" {...rest}>
      <Flex
        px={{ base: "0px", "2xl": "10px" }}
        justifyContent="space-between"
        alignItems="center"
        w="100%"
        mb="8px"
      >
        <Text color={textColor} fontSize="md" fontWeight="600" mt="4px">
          Répartition des patients
        </Text>
      </Flex>

      <PieChart
        h="100%"
        w="100%"
        chartData={chartData}
        chartOptions={{
          ...pieChartOptions,
          tooltip: {
            ...pieChartOptions.tooltip,
            y: {
              formatter: (value) => `${value.toFixed(1)}%`,
            },
          },
        }}
      />

      <Card
        bg={cardColor}
        flexDirection={{ base: "column", md: "row" }}
        boxShadow={cardShadow}
        w="100%"
        p="15px"
        px="20px"
        mt="15px"
        mx="auto"
      >
        {["Homme", "Femme", "Enfant"].map((label, index) => (
          <React.Fragment key={index}>
            <Flex direction="column" py="5px">
            <Flex 
              direction="column" 
              py="5px" 
              align={{ base: "center", md: "flex-start" }} // Center on mobile, start on larger screens
              width="100%" 
            >
                <Box
                  h="8px"
                  w="8px"
                  bg={pieChartOptions.colors[index]}
                  borderRadius="50%"
                  me="4px"
                />
                <Text
                  fontSize="xs"
                  color="secondaryGray.600"
                  fontWeight="700"
                  mb="5px"
                >
                  {label}
                </Text>
              </Flex>
              <Text fontSize="lg" color={textColor} fontWeight="700">
                {chartData[index].toFixed(1)}%
              </Text>
            </Flex>
            {index < 3 && (
              <VSeparator mx={{ base: "60px", xl: "30px", "2xl": "60px" }} />
            )}
          </React.Fragment>
        ))}
      </Card>
    </Card>
  );
}
