import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import PieChart from "components/charts/PieChart";
import { VSeparator } from "components/separator/Separator";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_URL } from "../../../../constants";
import {getAuthToken} from "../../../../helpers/helpers"

export const pieChartOptions = {
  labels: ["Diabète", "HTA", "Grossesse", "Allaitement", "Aucune Pathologie"],
  colors: ["#0066FF", "#33FFFF", "#0000CC", "#B3E5FC", "#90CAF9"],
  chart: {
    width: "50px",
  },
  states: {
    hover: {
      filter: {
        type: "none",
      },
    },
  },
  legend: {
    show: false,
  },
  dataLabels: {
    enabled: false,
  },
  hover: { mode: null },
  plotOptions: {
    donut: {
      expandOnClick: false,
      donut: {
        labels: {
          show: false,
        },
      },
    },
  },
  fill: {
    colors: ["#0066FF", "#33FFFF", "#0000CC", "#B3E5FC", "#90CAF9"],
  },
  tooltip: {
    enabled: true,
    theme: "dark",
  },
};

export default function Conversion({ selectedDate, ...rest }) {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const cardColor = useColorModeValue("white", "navy.700");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );

  const [chartData, setChartData] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // Ajout d'un état de chargement

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); // Démarre le chargement
      let diab = 0;
      let HTA = 0;
      let enc = 0;
      let allait = 0;
      let sain = 0;
  
      const token = getAuthToken();

      try {
        const response = await axios.get(
          `${API_URL}/api/track/action/AFFICHER_TRAITEMENT`,
          {
            headers: {
              "x-access-token": token,
              Authorization: `Bearer ${token}`,
            },
          }
        );
  
        const filteredData = response.data.filter((item) => {
          const createdAt = new Date(item.created_at);
          if (selectedDate) {
            return createdAt.toISOString().split("T")[0] === selectedDate;
          }
          return true;
        });
  
        let total = filteredData.length;
  
        filteredData.forEach((item) => {
          if (item.data.patientProfile.profileProperties.femme_enceinte) {
            enc++;
          }
          if (item.data.patientProfile.profileProperties.allaitement) {
            allait++;
          }
          if (item.data.patientProfile.profileProperties.hta) {
            HTA++;
          }
          if (item.data.patientProfile.profileProperties.diabete) {
            diab++;
          }
          if (
            !item.data.patientProfile.profileProperties.femme_enceinte &&
            !item.data.patientProfile.profileProperties.allaitement &&
            !item.data.patientProfile.profileProperties.hta &&
            !item.data.patientProfile.profileProperties.diabete
          ) {
            sain++;
          }
        });
  
        const diabPercentage = total > 0 ? (diab / total) * 100 : 0;
        const HTAPercentage = total > 0 ? (HTA / total) * 100 : 0;
        const encPercentage = total > 0 ? (enc / total) * 100 : 0;
        const allaitPercentage = total > 0 ? (allait / total) * 100 : 0;
        const sainPercentage = total > 0 ? (sain / total) * 100 : 0;
  
        setChartData([
          diabPercentage,
          HTAPercentage,
          encPercentage,
          allaitPercentage,
          sainPercentage,
        ]);
      } catch (error) {
        console.log("Error making the request:", error);
        setChartData([0, 0, 0, 0, 0]);
      }
      setIsLoading(false); // Terminé le chargement
    };
  
    fetchData();
  }, [selectedDate]); // Ensure selectedDate is added as a dependency
  
  if (isLoading) {
    return <div>Chargement...</div>; // Ajout d'un indicateur de chargement
  }

  if (chartData === null) {
    return <div>Pas données disponibles</div>; // Message si pas de données
  }

  return (
    <Card p="20px" align="center" direction="column" w="400%" {...rest}>
      <Flex
        px={{ base: "0px", "2xl": "10px" }}
        justifyContent="space-between"
        alignItems="center"
        w="200%"
        mb="8px"
      >
        <Text color={textColor} fontSize="md" fontWeight="600" mt="4px">
          Profils Patients
        </Text>
      </Flex>

      <PieChart
        key={selectedDate} // Utilisation de selectedDate comme clé pour forcer le re-rendu
        w="200%"
        p="15px"
        px="20px"
        mt="15px"
        mx="auto"
        chartData={chartData}
        chartOptions={{
          ...pieChartOptions,
          tooltip: {
            ...pieChartOptions.tooltip,
            y: {
              formatter: (value) => `${value.toFixed(1)}%`,
            },
          },
        }}
      />

      <Card
        bg={cardColor}
        justifyContent="center"
        flexDirection={{ base: "column", md: "row" }} // Stack on mobile, row on larger screens
        boxShadow={cardShadow}
        w="100%"
        p="15px"
        px="20px"
        mt="15px"
        mx="auto"
      >
        {[
          "Diabète",
          "HTA",
          "Grossesse",
          "Allaitement",
          "Aucune Pathologie",
        ].map((label, index) => (
          <React.Fragment key={index}>
            <Flex 
              direction="column" 
              py="5px" 
              align={{ base: "center", md: "flex-start" }} // Center on mobile, start on larger screens
              width="100%" // Ensure full width for stacking
            >
              <Flex align="center">
                <Box
                  h="8px"
                  w="8px"
                  bg={pieChartOptions.colors[index]}
                  borderRadius="50%"
                  me="4px"
                />
                <Text
                  fontSize="xs"
                  color="secondaryGray.600"
                  fontWeight="700"
                  mb="5px"
                >
                  {label}
                </Text>
              </Flex>
              <Text fontSize="lg" color={textColor} fontWeight="700">
                {chartData[index].toFixed(1)}%
              </Text>
            </Flex>
            {index < 4 && (
              <VSeparator mx={{ base: "0", md: "30px" }} /> // Add separator only on larger screens
            )}
          </React.Fragment>
        ))}
      </Card>
    </Card>
  );
}
