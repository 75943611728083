import { Box, Flex, Icon, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import LineChart from "components/charts/LineChart";
import React, { useEffect, useState } from "react";
import { MdBarChart } from "react-icons/md";
import { RiArrowUpSFill } from "react-icons/ri";
import axios from "axios";
import { differenceInMonths } from "date-fns";
import { API_URL } from "../../../../constants";
import {getAuthToken} from "../../../../helpers/helpers"

export const lineChartOptions = {
  chart: {
    toolbar: {
      show: false,
    },
    dropShadow: {
      enabled: true,
      top: 13,
      left: 0,
      blur: 10,
      opacity: 0.1,
      color: "#4318FF",
    },
  },
  colors: ["#4318FF"],
  markers: {
    size: 0,
    colors: "white",
    strokeColors: "#7551FF",
    strokeWidth: 3,
    strokeOpacity: 0.9,
    strokeDashArray: 0,
    fillOpacity: 1,
    discrete: [],
    shape: "circle",
    radius: 2,
    offsetX: 0,
    offsetY: 0,
    showNullDataPoints: true,
  },
  tooltip: {
    theme: "dark",
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
    type: "line",
  },
  xaxis: {
    type: "datetime",
    categories: [],
    labels: {
      style: {
        colors: "#A3AED0",
        fontSize: "12px",
        fontWeight: "500",
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    show: true,
  },
  legend: {
    show: false,
  },
  grid: {
    show: true,
    column: {
      color: ["#7551FF"],
      opacity: 0.5,
    },
  },
  color: ["#7551FF"],
};

export default function ProperimeSoldChart({ selectedDate, ...rest }) {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const iconColor = useColorModeValue("brand.500", "white");

  const [count, setCount] = useState(0);
  const [dailyProperimeSold, setDailyProperimeSold] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const token = getAuthToken();

      try {
        const response = await axios.get(`${API_URL}/api/cart`, {
          headers: { "x-access-token": token },
          "Authorization": `Bearer ${localStorage.getItem("token")}`

        });

        const cartData = response.data;
        const properimeSoldPerDay = {};

        cartData.forEach((cart) => {
          const cartDate = new Date(cart.created_at);
          cart.items.forEach((item) => {
            const { date_peremption } = item.medicamentDetails;
            const expirationDate = new Date(date_peremption);

            // Vérifier si c'est un produit properime
            const monthsDifference = differenceInMonths(expirationDate, cartDate);
           if (monthsDifference >= 0 && monthsDifference < 6) {
              const formattedDate = cartDate.toISOString().split("T")[0];

              if (!properimeSoldPerDay[formattedDate]) {
                properimeSoldPerDay[formattedDate] = 0;
              }
              properimeSoldPerDay[formattedDate] += item.quantity;
            }
          });
        });

        const properimeSoldArray = Object.entries(properimeSoldPerDay).map(
          ([date, quantity]) => ({
            date,
            quantity,
          })
        );

        setDailyProperimeSold(properimeSoldArray);
        setCount(properimeSoldArray.reduce((sum, { quantity }) => sum + quantity, 0));
      } catch (error) {
        console.log("Error fetching cart data:", error);
      }
    };

    fetchData();
  }, [selectedDate]);

  const lineChartData = [
    {
      name: "Properime Sold",
      data: dailyProperimeSold.map((item) => ({
        x: new Date(item.date),
        y: item.quantity,
      })),
    },
  ];

  return (
    <Card
      justifyContent="center"
      align="center"
      direction="column"
      w="100%"
      mb="0px"
      {...rest}
    >
      <Flex justify="space-between" ps="0px" pe="20px" pt="5px">
        <Flex align="center" w="100%">
          <Text
            me="auto"
            color={textColor}
            fontSize="xl"
            fontWeight="700"
            lineHeight="100%"
          >
            Nombre de produits properime vendus
          </Text>
          <Icon as={MdBarChart} color={iconColor} w="24px" h="24px" />
        </Flex>
      </Flex>
      <Flex w="100%" flexDirection={{ base: "column", lg: "row" }}>
        <Flex flexDirection="column" me="20px" mt="28px">
          <Text
            color={textColor}
            fontSize="34px"
            textAlign="start"
            fontWeight="700"
            lineHeight="100%"
          >
            {count}
          </Text>
          <Flex align="center" mb="20px">
            <Text
              color="secondaryGray.600"
              fontSize="sm"
              fontWeight="500"
              mt="4px"
              me="12px"
            >
              Nombre total de properimes vendus
            </Text>
            <Flex align="center">
              <Icon as={RiArrowUpSFill} color="green.500" me="2px" mt="2px" />
            </Flex>
          </Flex>
        </Flex>
        <Box minH="260px" minW="75%" mt="auto">
          {dailyProperimeSold.length > 0 && (
            <LineChart
              chartData={lineChartData}
              chartOptions={lineChartOptions}
            />
          )}
        </Box>
      </Flex>
    </Card>
  );
}
