import { Box, Flex, Icon, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import LineChart from "components/charts/LineChart";
import React, { useEffect, useState } from "react";
import { MdBarChart } from "react-icons/md";
import { RiArrowUpSFill } from "react-icons/ri";
import axios from "axios";
import { API_URL } from "../../../../constants";
import {getAuthToken} from "../../../../helpers/helpers"

export const lineChartOptionsTotalSpent = {
  chart: {
    toolbar: {
      show: false,
    },
    dropShadow: {
      enabled: true,
      top: 13,
      left: 0,
      blur: 10,
      opacity: 0.1,
      color: "#4318FF",
    },
  },
  colors: ["#4318FF", "#39B8FF"],
  markers: {
    size: 0,
    colors: "white",
    strokeColors: "#7551FF",
    strokeWidth: 3,
    strokeOpacity: 0.9,
    strokeDashArray: 0,
    fillOpacity: 1,
    discrete: [],
    shape: "circle",
    radius: 2,
    offsetX: 0,
    offsetY: 0,
    showNullDataPoints: true,
  },
  tooltip: {
    theme: "dark",
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
    type: "line",
  },
  xaxis: {
    type: "valeric",
    categories: [
      "JAN",
      "FEV",
      "MAR",
      "AVR",
      "MAI",
      "JUIN",
      "JUIL",
      "AOU",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ],
    labels: {
      style: {
        colors: "#A3AED0",
        fontSize: "12px",
        fontWeight: "500",
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    show: false,
  },
  legend: {
    show: false,
  },
  grid: {
    show: false,
    column: {
      color: ["#7551FF", "#39B8FF"],
      opacity: 0.5,
    },
  },
  color: ["#7551FF", "#39B8FF"],
};

export default function TotalSpent({ selectedDate, ...rest }) {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const iconColor = useColorModeValue("brand.500", "white");

  const [count, setCount] = useState(0);
  const [monthlyCounts, setMonthlyCounts] = useState(Array(12).fill(0));
  const [monthlyRevenue, setMonthlyRevenue] = useState(Array(12).fill(0));

  useEffect(() => {
    console.log(monthlyCounts);
  }, [monthlyCounts, monthlyRevenue]);

  function calculateMonthlyRevenue(data) {
    const revenue = Array(12).fill(0);
    data.forEach((entry) => {
      const date = new Date(entry.created_at);
      const month = date.getMonth();
      const year = date.getFullYear();
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth();
      entry.items.forEach((item) => {
        if (item.medicamentDetails) {
          const price = item.medicamentDetails.prix;
          const quantity = item.quantity;
          const itemRevenue = price * quantity;
          if (year === currentYear && month <= currentMonth) {
            revenue[month] += itemRevenue;
          }
        }
      });
    });
    return revenue.map((val) => parseFloat(val.toFixed(0)));
  }

  useEffect(() => {
    const fetchData = async () => {
      const token = getAuthToken();

      // Fetch the data from both APIs
      const response1 = axios
        .get(`${API_URL}/api/track/action/AFFICHER_TRAITEMENT`, {
          headers: { 
            "x-access-token": token,
            Authorization: `Bearer ${token}`,
           },
        })
        .catch((error) => {
          console.log("Error fetching AFFICHER_TRAITEMENT:", error);
          return { data: [] };
        });

      const response2 = axios
        .get(`${API_URL}/api/cart`, {
          headers: { 
            "x-access-token": token,
            Authorization: `Bearer ${token}`,
           },
        })
        .catch((error) => {
          console.log("Error fetching cart:", error);
          return { data: [] };
        });

      try {
        const [res1, res2] = await Promise.all([response1, response2]);

        // Filter the results based on `selectedDate`
        const filteredData1 = res1.data.filter((item) => {
          const createdAt = new Date(item.created_at).toISOString().split("T")[0];
          return !selectedDate || createdAt === selectedDate;
        });

        const filteredData2 = res2.data.filter((item) => {
          const createdAt = new Date(item.created_at).toISOString().split("T")[0];
          return !selectedDate || createdAt === selectedDate;
        });

        // Processing filtered data
        const date = filteredData1.map(item => item.created_at);

        const counter = date.reduce((accumulator, currVal) => {
          if (currVal) {
            const created_at = currVal.split("T")[0];
            if (Object.keys(accumulator).some((elem) => elem === created_at)) {
              accumulator[created_at] += 1;
            } else {
              accumulator[created_at] = 1;
            }
          }
          return accumulator;
        }, {});

        const counterArray = Object.entries(counter);
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth();
        const counts = Array(12).fill(0);

        counterArray.forEach(([date, val]) => {
          const dateObj = new Date(date);
          const year = dateObj.getFullYear();
          const month = dateObj.getMonth();

          if (year === currentYear && month <= currentMonth) {
            counts[month] += val;
          }
        });

        setMonthlyCounts(counts);
        setCount(filteredData1.length);

        const revenue = calculateMonthlyRevenue(filteredData2);
        setMonthlyRevenue(revenue);

      } catch (error) {
        console.log("Error making the request:", error);
      }
    };

    fetchData();
  }, [selectedDate]);

  const lineChartDataTotalSpent = [
    {
      name: "Revenues",
      data: monthlyRevenue,
    },
    {
      name: "Utilisations",
      data: monthlyCounts,
    },
  ];

  return (
    <Card
      justifyContent="center"
      align="center"
      direction="column"
      w="100%"
      mb="0px"
      {...rest}
    >
      <Flex justify="space-between" ps="0px" pe="20px" pt="5px">
        <Flex align="center" w="100%">
          <Text
            me="auto"
            color={textColor}
            fontSize="xl"
            fontWeight="700"
            lineHeight="100%"
          >
            Nombre d'utilisations totales
          </Text>
          <Icon as={MdBarChart} color={iconColor} w="24px" h="24px" />
        </Flex>
      </Flex>
      <Flex w="100%" flexDirection={{ base: "column", lg: "row" }}>
        <Flex flexDirection="column" me="20px" mt="28px">
          <Text
            color={textColor}
            fontSize="34px"
            textAlign="start"
            fontWeight="700"
            lineHeight="100%"
          >
            {count}
          </Text>
          <Flex align="center" mb="20px">
            <Text
              color="secondaryGray.600"
              fontSize="sm"
              fontWeight="500"
              mt="4px"
              me="12px"
            >
              Nombre d'utilisations
            </Text>
            <Flex align="center">
              <Icon as={RiArrowUpSFill} color="green.500" me="2px" mt="2px" />
            </Flex>
          </Flex>
        </Flex>
        <Box minH="260px" minW="75%" mt="auto">
          {monthlyCounts.length > 0 && monthlyRevenue.length > 0 && (
            <LineChart
              chartData={lineChartDataTotalSpent}
              chartOptions={lineChartOptionsTotalSpent}
            />
          )}
        </Box>
      </Flex>
    </Card>
  );
}
