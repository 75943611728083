import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import rehypeRaw from 'rehype-raw';
import './TermsAndConditions.css'

const TermsAndConditions = () => {

  const history = useHistory();
  const [termsText, setTermsText] = useState("");
 

  useEffect(() => {
    const fetchTerms = async () => {
      const response = await fetch("/termsAndConditions.md");
      const text = await response.text();
      setTermsText(text);
    };

    fetchTerms();
  }, []);

  return (
    <>
      <div className="container">
        <div className="card">
          <p className="title">
            CONDITIONS GÉNÉRALES D’UTILISATION DE LA PLATEFORME NUMÉRIQUE
            SAYDALID
          </p>
          <p className="notice">
            Vous ne pourrez utiliser cette application qu'en acceptant les
            conditions générales d'utilisation.
            <br />
            Veuillez lire attentivement l'intégralité des conditions en faisant
            défiler jusqu'en bas de la section.
          </p>
          <p className="last-update">Mise à jour le: 24/09/2024</p>
          <div className="termsContainer">
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>
              {termsText}
            </ReactMarkdown>
          </div>
          <button
            onClick={() => history.push("/admin")}
            className="acceptButton"
          >
            Retour au tableau de bord
          </button>
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;
